function removeNameAttribute(field) {
    if ($('[name="' + field + '"]').val() === '0' || $('[name="' + field + '"]').val() === '') {
        $('[name="' + field + '"]').removeAttr('name');
        if (field === 'tx_news_pi1[search][subject]') {
            $('[name="tx_news_pi1[__referrer][@request]"]').removeAttr('name');
            $('[name="tx_news_pi1[__referrer][@extension]"]').removeAttr('name');
            $('[name="tx_news_pi1[__referrer][@vendor]"]').removeAttr('name');
            $('[name="tx_news_pi1[__referrer][@controller]"]').removeAttr('name');
            $('[name="tx_news_pi1[__referrer][@action]"]').removeAttr('name');
            $('[name="tx_news_pi1[__referrer][arguments]"]').removeAttr('name');
            $('[name="tx_news_pi1[__trustedProperties]"]').removeAttr('name');
        }
        if (field === 'tx_news_pi1[overwriteDemand][datecomplete]') {
            $('[name="tx_news_pi1[overwriteDemand][startFromThisDate]"]').removeAttr('name');
        }
        if (field === 'tx_stratismap_location[keyword]') {
            $('[name="tx_stratismap_location[__referrer][@extension]"]').removeAttr('name');
            $('[name="tx_stratismap_location[__referrer][@vendor]"]').removeAttr('name');
            $('[name="tx_stratismap_location[__referrer][@controller]"]').removeAttr('name');
            $('[name="tx_stratismap_location[__referrer][@action]"]').removeAttr('name');
            $('[name="tx_stratismap_location[__referrer][arguments]"]').removeAttr('name');
            $('[name="tx_stratismap_location[__referrer][@request]"]').removeAttr('name');
            $('[name="tx_stratismap_location[__trustedProperties]"]').removeAttr('name');
        }
    }
}

function showSubcat() {
    var selectedParentCategory = $('#categories').find('option:selected').val();
    if (selectedParentCategory !== '' && selectedParentCategory !== '0') {
        $('.subcat').addClass('hidden').find('select').attr('disabled', true);
        var selectedSubCategoryWrapper = $('[data-id="' + selectedParentCategory + '"]');
        if (selectedSubCategoryWrapper.length > 0) {
            $('.default-subcat').addClass('hidden').find('select').attr('disabled', true);
            selectedSubCategoryWrapper.removeClass('hidden').find('select').removeAttr('disabled');
        } else {
            $('.default-subcat').removeClass('hidden');
        }
    } else {
        $('.subcat').addClass('hidden');
        $('.default-subcat').removeClass('hidden');
    }
}

function catmenu() {
    // report des valeurs des checkbox dans un champ hidden
    if ($('.checkbox.multi').length > 0 && $('input[type="hidden"].getMulti').length > 0) {

        var $input = $('input[type="hidden"].getMulti', '.checkbox.multi');
        var $checkboxes = $(':checkbox', '.checkbox.multi');

        // This function updates the hidden input with selected options
        var updateCategoriesField = function () {

            // Get a list of checked boxes values
            var selectedValues = $checkboxes.filter(':checked').map(function () {
                return this.value;
            }).get().join();

            // Insert new value into hidden field
            $input.val(selectedValues);
        };

        // Bind update on load and checkbox changes
        updateCategoriesField();
        $checkboxes.on('change', updateCategoriesField);
    }

    // coche les checkbox enfants d'une checkbox parente
    if ($('.check_radio_item__sub').length > 0) {
        $('.check_radio_item__sub').parent().find('> input[type="checkbox"]').on('change', function () {
            if ($(this).is(':checked')) {
                $(this).nextAll('.check_radio_item__sub').find('input[type="checkbox"]').prop('checked', true).trigger('change');
            } else {
                $(this).nextAll('.check_radio_item__sub').find('input[type="checkbox"]').prop('checked', false).trigger('change');
            }
        });
        $('.check_radio_item__sub input[type="checkbox"]').on('change', function () {
            if (!$(this).is(':checked') && $(this).parents('.check_radio_item__sub').parent().find('> input[type="checkbox"]').is(':checked')) {
                $(this).parents('.check_radio_item__sub').parent().find('> input[type="checkbox"]').prop('checked', false);
            }
        });

    }

    if ($('.filters').length > 0) {
        // gestion du menu des archives pour news
        if ($('.filters #tx_news_month').length > 0) {
            if ($('#tx_news_month option:selected').length == 1) {
                catmenuYear = $('#tx_news_month option[selected="selected"]').parent('optgroup').attr('label');
                $('input#tx_news_year').val(catmenuYear);
            }
            $('#tx_news_month').change(function () {
                catmenuYear = $(this).find('option:selected').parent('optgroup').attr('label');
                if (catmenuYear == 'undefined') {
                    $('input#tx_news_year').val('');
                } else {
                    $('input#tx_news_year').val(catmenuYear);
                }
            });
        }

        // cat/sous-cat
        if ($('.subcats').length > 0) {
            showSubcat();
            $('#categories').on('change', function () {
                showSubcat();
            });
        }

        // action à la validation du formulaire
        $('.filters form').on('submit', function () {
            if ($('.subcat').length > 0) {
                if ($('.subcat:visible').length > 0 && $('.subcat select:visible').val() !== '0' && $('.subcat select:visible').val() !== '') {
                    $('.hiddenCat').val($('.subcat select:visible').val());
                }
                else if ($('#categories').val() !== '0' && $('#categories').val() !== '') {
                    $('.hiddenCat').val($('#categories').val());
                } else {
                    $('.hiddenCat').val('');
                }
            }

            // datepicker
            if ($('#datepicker-hidden').length > 0) {
                if ($('#datepicker-hidden').val().length > 0) {
                    var tab = $('#datepicker-hidden').val().split('-');
                    $('#dated').val(tab[2]);
                    $('#datem').val(tab[1]);
                    $('#datey').val(tab[0]);
                }
            }

            // supprime les attributs name si les champs sont vides, pour éviter d'ajouter des paramètres vides dans l'url
            var nameAttributeToRemove = [
                'tx_news_pi1[overwriteDemand][period]',
                'tx_news_pi1[overwriteDemand][related]',
                'tx_news_pi1[overwriteDemand][categories]',
                'tx_news_pi1[overwriteDemand][day]',
                'tx_news_pi1[overwriteDemand][month]',
                'tx_news_pi1[overwriteDemand][year]',
                'tx_news_pi1[overwriteDemand][keywords]',
                'tx_news_pi1[overwriteDemand][datecomplete]',
                'tx_news_pi1[search][subject]',
                'tx_news_pi1[overwriteDemand][tags]',
                'tx_news_pi1[overwriteDemand][relatedLinks]',
                'tx_news_pi1[overwriteDemand][related]',
                'tx_news_pi1[overwriteDemand][relatedLocations]',
                'tx_news_pi1[overwriteDemand][relatedAreas]',
                'tx_simplepoll_polllisting[category]',
                'tx_simplepoll_polllisting[status]',
                'tx_stratismap_location[search][keywords]',
                'tx_stratismap_location[search][categories][]'
            ];
            for (var iterator in nameAttributeToRemove) {
                removeNameAttribute(nameAttributeToRemove[iterator]);
            }
        });
    }
}
