function enableDatePicker() {
    // Input date is supported ?
    var hasNativeDatePicker = function () {
        var elem = document.createElement('input');

        elem.setAttribute('type', 'date');
        elem.value = 'foo';

        return (elem.type === 'date' && elem.value !== 'foo');
    };

    // If has no native datepicker (Firefox)
    if (!hasNativeDatePicker()) {
        function frenchToEnglishDate(frenchDate, separatorIn, separatorOut) {
            var dateElem = frenchDate.split(separatorIn);
            return dateElem[2] + separatorOut + dateElem[1] + separatorOut + dateElem[0];
        }

        function showInternationalDate(argObj) {
            var argObjId = argObj.id || argObj.attr('id'),
                argObjDate = argObj.date || new Date(frenchToEnglishDate(argObj.val()), '-', '/'),
                inputHidden = document.getElementById(argObjId + '-hidden');

            inputHidden.value = datePickerController.printFormattedDate(argObjDate, "%Y-%m-%d", false);
        }

        $('input[type="date"]').each(function () {
            var thisId = $(this).attr('id'),
                thisName = $(this).attr('name'),
                obj = {};

            $(this)
                .attr('data-name',thisName)
                .removeAttr('name')
                .after('<input type="hidden" name="' + thisName + '" id="' + thisId + '-hidden" value="">')
                .on("change", function () {
                    var inputHidden = $(this).nextAll('#'+thisId+'-hidden');
                    var formatDate = frenchToEnglishDate($(this).val(), '/', '-');
                    inputHidden.val(formatDate);
                });

            if ($(this).val() !== '') {
                showInternationalDate($(this));
            }

            obj[thisId] = "%d/%m/%Y";
            datePickerController.createDatePicker({
                formElements:      obj,
                callbackFunctions: {
                    "datereturned": [showInternationalDate]
                }
            });

        });
    }
    // If has native datepicker (Chrome, Opera, Safari)
    else {
        $('input[type="date"]').each(function () {
            var thisId = $(this).attr('id'),
                thisValue = $(this).val() || '',
                thisName = $(this).attr('name');

            $(this)
                .attr('data-name',thisName)
                .removeAttr('name')
                .after('<input type="hidden" name="' + thisName + '" id="' + thisId + '-hidden" value="' + thisValue + '">')
                .on("change", function () {
                    var inputHidden = $(this).next();
                    inputHidden.val($(this).val());
                });
        });
    }
}

