// Caroussel2
var carouselWrap2 = $('.carrousel2'),
    carousel2 = $('.listItems', carouselWrap2),
    reinitTimer2;
//console.log(carousel2);
function carrousel2(itemVisible) {
    //console.log(itemVisible);
    if (carouselWrap2.length > 0) {
        var nbItem = $('.item', carousel2).length,
            nbItemConfig;
        if (itemVisible) {
            nbItemConfig = itemVisible;
        } else {
            nbItemConfig = '4';
        }
        if (nbItem > nbItemConfig) {
            if ($('.prevNextCaroussel2').length == 0) {
                carousel2.parent().append('<ul class="prevNextCaroussel2">' +
                    '<li class="prevCaroussel2"><button type="button" class="button-reset"><span class="prev">Précédent</span></button></li>' +
                    '<li class="nextCaroussel2"><button type="button" class="button-reset"><span class="next">Suivant</span></button></li>' +
                    '</ul>');
            }
            //console.log(carousel2);
            carousel2.cycle({
                log:             false,
                fx:              'carousel',
                carouselVisible: nbItemConfig,
                allowWrap:       false,
                carouselFluid:   true,
                swipe:           true,
                timeout:         0,
                prev:            '.prevCaroussel2 button',
                next:            '.nextCaroussel2 button',
                slides:          '.item',
                autoHeight:      'calc'
            });
            $('.item', carousel2).css('opacity', '1');
        }
    }
}
var destroyCarrousel2 = function () {
    $('.carrousel2 .listItems').cycle('destroy');
}
function reinit_cycle2(nbItem) {
    setTimeout(function () {
        destroyCarrousel2();
        setTimeout(function () {
            carrousel2(nbItem);
        }, 200);
    }, 200);
}

function createColumnItems(view) {
    var items = $('.services__item', carousel2);
    $(carousel2).empty();
    if (view === 'mediaDesktop') {
        $(carousel2).append(items);
    } else {
        for (var i = 1; i <= items.length; i++) {
            if ((i % 2) != 0) {
                var el = document.createElement('div');
                el.classList.add('item');
                el.appendChild(items[i - 1]);
                carousel2[0].appendChild(el);
            } else {
                var itemsLength = carousel2.find('.item').length - 1;
                carousel2.find('.item')[itemsLength].appendChild(items[i - 1]);
            }
        }
    }
}

function carrousel2State(view) {
    if (view === 'mediaDesktop') {
        destroyCarrousel2();
        createColumnItems(view);
    } else if (view === 'mediaTablet') {
        clearTimeout(reinitTimer2);
        reinitTimer2 = setTimeout(reinit_cycle2('3'), 200);
        createColumnItems(view);
    } else if (view === 'mediaSmartphone') {
        clearTimeout(reinitTimer2);
        reinitTimer2 = setTimeout(reinit_cycle2('1'), 200);
        createColumnItems(view);
    }
}
