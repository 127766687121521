// Caroussel3
var carouselWrap = $('.carrousel3'),
    carousel = $('.listItems', carouselWrap),
    reinitTimer;
function carrousel3(itemVisible) {
    if (carouselWrap.length > 0) {
        var nbItem = $('.item', carousel).length,
            nbItemConfig;
        if (itemVisible) {
            nbItemConfig = itemVisible;
        } else {
            nbItemConfig = '4';
        }
        if (nbItem > nbItemConfig) {
            if ($('.prevNextCaroussel3').length == 0) {
                carousel.parent().append('<ul class="prevNextCaroussel3">' +
                    '<li class="prevCaroussel3"><button type="button" class="button-reset3"><span class="prev">Précédent</span></button></li>' +
                    '<li class="nextCaroussel3"><button type="button" class="button-reset3"><span class="next">Suivant</span></button></li>' +
                    '</ul>');
            }
            carousel.cycle({
                log:             false,
                fx:              'carousel',
                carouselVisible: nbItemConfig,
                carouselFluid:   true,
                swipe:           true,
                timeout:         4000,
                prev:            '.prevCaroussel3 button',
                next:            '.nextCaroussel3 button',
                slides:          '.item',
                autoHeight:      'calc'
            });
            $('.item', carousel).css('opacity', '1');
        } else if (nbItem <= 1) {
            destroyCarrousel3();
        } else {
            carousel.css('flex-direction', 'row');
        }
    }
}
var destroyCarrousel3 = function () {
    $('.carrousel3 .listItems').cycle('destroy');
}
function reinit_cycle(nbItem) {
    setTimeout(function () {
        destroyCarrousel3();
        setTimeout(function () {
            carrousel3(nbItem);
        }, 200);
    }, 200);
}
function carrousel3State() {
    clearTimeout(reinitTimer);
    reinitTimer = setTimeout(reinit_cycle('1'), 200);
}
