// Search
/*function searchState(state) {
    if (state == 'mediaDesktop') {
        $('.search-box__wrapper').dropDownMenu({
            disabled:    true,
            classBtn:    'button-1 button-1--no-icon button-1--svg',
            independent: false
        });
    } else if ((state == 'mediaSmartphone') || (state == 'mediaTablet')) {
        //$('#searchform .dropDownMenu').dropDownMenu(reset);
        $('.search-box__wrapper').dropDownMenu({
            disabled:    false,
            classBtn:    'button-1 button-1--no-icon button-1--svg',
            independent: false
        });
    }
}*/

function searchState(state) {
    if ((state == 'mediaSmartphone') || (state == 'mediaTablet') || (state == 'mediaDesktop')) {
        //$('#searchform .dropDownMenu').dropDownMenu(reset);
        $('.search-box__wrapper').dropDownMenu({
            disabled:    false,
            classBtn:    'button-1 button-1--no-icon button-1--svg',
            independent: false
        });
    }
}

$(document).ready(function () {
    var searchTrigger = $('.search-btn-outside'),
        searchBox = $('#search');

    if(searchTrigger.length && searchBox.length) {
        searchTrigger.click(function () {
            $('.header .ddm__button').trigger('click');
            searchBox.animate({
                top: '0'
            }, 500);
            searchBox.addClass('is-active');
            return false;
        });
    }

    $(document).on('click', function (e) {

        var element = e.target;

        if(!searchBox.is(element) && searchBox.has(element).length === 0) {
            searchBox.animate({
                top: '-100%'
            }, 500);
        }

    });

});

