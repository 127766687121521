$(document).ready(function () {
    // var api = $('.map__search .container').jScrollPane({
    //   showArrows: true,
    //   autoReinitialise: true,
    //   animateScroll: true
    // }).data('jsp');
    //
    // $('.map__search__arrow.scroll-up').click(function() {
    //     api.scrollByY(-50);
    //     return false;
    // });
    $(".filters__reset").on('click', function () {
        $('.filters__hide').trigger('click');
    });
    // $('.map__search__arrow.scroll-down').click(function() {
    //   api.scrollByY(50);
    //   return false;
    // });
    $('.filters__hide').click(function () {
        $('.map__search .column-close').trigger('click');
        toggleMapSearchResults();
    });
    // showArrows();

    $('.map-categories__button').on('click', function () {
        var currentId = $(this).attr('data-filter-id');
        toggleCategoryClass($(this));
        changeCategoryFilterForm(currentId);
        changeFilterColumnClass();
        //$scope.updateSize();
    });

    $('.map-column--filter .column-close').on('click', function () {
        $(this).parents('.setPos').addClass('is-hidden');
        $('.map-categories > button').removeClass('is-active');
        $('.filters__hide').trigger('click');
        $scope.updateSize();
    });

});
//
// function addCategoryClass() {
//     var categoriesButtonActive = $('.map-categories__button.is-active'),
//         categoriesButton = $('.map-categories__button');
//
//     if (!categoriesButtonActive.length) {
//         categoriesButton.first().addClass('is-active');
//     }
// }
// function showArrows() {
//     var panelHeight = $('.jspPane').height();
//     var containerHeight = $('.carto .map__search .container').height();
//
//     if (panelHeight > containerHeight) {
//         $('.map__search__arrow').addClass('map__search__arrow--visible');
//         $('.carto .map__search ul').removeClass('no-margin');
//
//     } else {
//         $('.map__search__arrow').removeClass('map__search__arrow--visible');
//         $('.carto .map__search ul').addClass('no-margin');
//     }
// }

function changeCategoryFilterForm(id) {
    var filter = $('.map-filter'),
        forms = filter.find('form');

    forms.each(function (index) {
        if ($(this).attr('data-form-id') === id) {
            $(this).addClass('is-visible');
        } else {
            $(this).removeClass('is-visible');
        }
    });
}

function toggleMapSearchResults() {
    var map = $('.map-content');
    var btn = $('.filters__hide');
    if (map.hasClass('map__search--active')) {
        btn.removeClass('filters__hide--hidden');
    } else {
        btn.addClass('filters__hide--hidden');
    }
}
// $(window).resize(function () {
//   $('.map__search .container').jScrollPane({
//     reinitialize: true
//   });
//
//   showArrows();
// });
/* CUSTOM FUNCTIONS */
// $(document).ready(function () {
//
//     toggleMapSearchResults();
//     changeFilterColumnClass();
//     addCategoryFilterClass();
//
//     $('.map-categories__button').on('click', function () {
//         var currentId = $(this).attr('data-filter-id');
//         toggleCategoryClass($(this));
//         changeCategoryFilterForm(currentId);
//         changeFilterColumnClass();
//         $scope.updateSize();
//     });
//
//     $('.map-column--filter .column-close').on('click', function () {
//         $(this).parents('.setPos').addClass('is-hidden');
//         $('.map-categories > button').removeClass('is-active');
//         $('.filters__hide').trigger('click');
//         $scope.updateSize();
//     });
//
// });
//
// function changeCategoryFilterForm(id) {
//     var filter = $('.map-filter'),
//         forms = filter.find('form');
//
//     forms.each(function (index) {
//         if ($(this).attr('data-form-id') === id) {
//             $(this).addClass('is-visible');
//         } else {
//             $(this).removeClass('is-visible');
//         }
//     });
// }
//
function toggleCategoryClass(category) {

    category.parent().find('> button').removeClass('is-active');

    if (!category.hasClass('is-active')) {
        category.addClass('is-active');
    }
}

function changeFilterColumnClass() {
    var button = $('.map-categories > button.is-active');

    if (!button.length) {
        $('.map-column--filter').parent().addClass('is-hidden');
    } else {
        $('.map-column--filter').parent().removeClass('is-hidden');
    }
}

function addCategoryFilterClass() {
    var forms = $('.map-filter > form');

    var id = $('.map-categories > button.is-active').attr('data-filter-id');

    forms.each(function () {
        if ($(this).attr('data-form-id') === id) {
            $(this).addClass('is-visible');
        }

    });
}
/* /CUSTOM FUNCTIONS */
