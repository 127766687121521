$(document).ready(function () {
    var mediaSmartphone = 'mediaSmartphone',
        mediaTablet = 'mediaTablet',
        mediaDesktop = 'mediaDesktop';

    var jRes = jRespond([
        {
            label: 'smartphone',
            enter: 0,
            exit: 767
        }, {
            label: 'tablet',
            enter: 768,
            exit: 1279
        }, {
            label: 'desktop',
            enter: 1280,
            exit: 10000
        }
    ]);

    jRes.addFunc([
        {
            breakpoint: 'smartphone',
            enter: function () {
                myInitFuncSmartphone();

            },
            exit: function () {
            }
        },
        {
            breakpoint: 'tablet',
            enter: function () {
                myInitFuncTablet();

            },
            exit: function () {
            }
        },
        {
            breakpoint: 'desktop',
            enter: function () {
                myInitFuncDesktop();

            },
            exit: function () {
            }
        }
    ]);

    function myInitFuncSmartphone() {
        fancyboxGalerie(mediaSmartphone); // 230_stratis_fancybox_galerie.js
        initGalerieClassique(mediaSmartphone); // 240_ stratis_galerie_classique.js
        searchState(mediaSmartphone); // 290_stratis_search.js
        filtersState(mediaSmartphone); // 300_stratis_filters.js
        carrousel1State(mediaSmartphone); // 330_stratis_carrousel1.js
        carrousel2State(mediaSmartphone); // 340_stratis_carrousel2.js
        carrousel3State(mediaSmartphone); // 340_stratis_carrousel2.js
        menuMain1State(mediaSmartphone); // 190_stratis_menu-main-1.js
    }

    function myInitFuncTablet() {
        fancyboxGalerie(mediaTablet); // 230_stratis_fancybox_galerie.js
        initGalerieClassique(mediaTablet); // 240_ stratis_galerie_classique.js
        searchState(mediaTablet); // 290_stratis_search.js
        filtersState(mediaTablet); // 300_stratis_filters.js
        carrousel1State(mediaTablet); // 330_stratis_carrousel1.js
        carrousel2State(mediaTablet); // 340_stratis_carrousel2.js
        carrousel3State(mediaTablet); // 340_stratis_carrousel2.js
        menuMain1State(mediaTablet); // 190_stratis_menu-main-1.js
    }

    function myInitFuncDesktop() {
        fancyboxGalerie(mediaDesktop); // 230_stratis_fancybox_galerie.js
        initGalerieClassique(mediaDesktop); // 240_stratis_galerie_classique.js
        searchState(mediaDesktop); // 290_stratis_search.js
        filtersState(mediaDesktop); // 300_stratis_filters.js
        carrousel1State(mediaDesktop); // 330_stratis_carrousel1.js
        carrousel2State(mediaDesktop); // 340_stratis_carrousel2.js
        carrousel3State(mediaDesktop); // 340_stratis_carrousel2.js
        menuMain1State(mediaDesktop); // 190_stratis_menu-main-1.js
    }

    // Generic functions
    preloadImages(); // 170_stratis_preload_images.js
    objectFitImages(); // 175_lib_ofi.min.js
    geocode(); // 205_stratis_geocode.js
    linkExternalNewWindow(); // 210_stratis_external_window.js
    fancyboxClassic(); // 220_stratis_fancybox_classic.js
    //fancyboxAutoHome();  // 235_stratis_fancybox_auto_home.js
    sharePage(); // 250_stratis_share_page.js
    eventDdm(); // 260_stratis_event-ddm.js
    contentDdm(); // 270_stratis_content-ddm.js
    manageVideo(); // 280_stratis_video.js
    enableDatePicker(); // 310_stratis_datetimepicker.js
    //enableTimePicker(); // 310_stratis_datetimepicker.js To remove
    formValidation(); // 320_stratis_form_validation.js
    agendaDatePicker(); // 325_agenda_date-picker.js
    submitAgenda(); // 325_agenda_date-picker.js
    //infiniteScroll(); // 350_stratis_infinite_scroll.js
    //showTotal(); // 350_stratis_infinite_scroll.js
    catmenu(); // 355_stratis_catmenu.js
    faqDdm(); // 370_stratis_faq.js
    addContentInTitle(); // 380_stratis_add_in_title.js
    //generatePie(); // 390_stratis_piechart.js
    autocomplete(); // 410_autocomplete.js

    tocProgress();

    function stickySidebar() {
        var sidebar = $('.sidebar'),
            sidebarHeight = sidebar.height();

        sidebar.css('height', sidebarHeight);

        if ($(window).width() < 1280) {
            sidebar.css('height', 'auto');
            sidebar.trigger('sticky_kit:detach');
        } else {
            sidebar.stick_in_parent({
                offset_top: 20
            });
        }
    }

    function tocProgress() {
        createStructure();
        $('#anchor-bar').tocProgress();
        setTimeout(stickySidebar, 2000);

        function createStructure() {
            if (!$('.section-main__content .rte .subpages-menu').length) {
                if ($('.section-main__content .rte h2').not('.ddm .rte h2').length) {
                    $('.sidebar').prepend('<div id="anchor-bar"><h2 class="sidebar-title">' + l10n['browseThisPage'] + '</h2></div>');
                    $('.section-main__content .rte h2, .kiosque-wrapper h2, .contact-cards h2, .telecharger-wrapper h2').not('.ddm .rte h2')
                        .addClass('side-anchor')
                        .each(function () {
                            $(this)
                                .nextUntil('.side-anchor')
                                .addBack()
                                .wrapAll('<div class="story">');
                        });
                }
            }
        }
    }

    $(window).on('resize', function () {
        stickySidebar();
    });

});

accessTooltipState(); // 400_stratis_access-tooltip.js


// $(window).on('load', function () {
//     var $list = $('.social-list'),
//         $filterBtns = $('.filter-social [data-filter]');
//     $list.isotope({
//         itemSelector: 'li',
//         masonry: {}
//     });
//     filter('.social-list-item');
//     toggleActive($filterBtns.eq(0));
//     $('.filter-social').on('click', 'a', function (e) {
//         e.preventDefault();
//         var $elem = $(this);
//         filter($elem.attr('data-filter'));
//         toggleActive($elem);
//     });
//
//     function filter(val) {
//         $list.isotope({
//             filter: val
//         });
//     }
//
//     function toggleActive($elem) {
//         deactivateAll();
//         makeActive($elem);
//     }
//
//     function makeActive($elem) {
//         $elem.addClass('active');
//     }
//
//     function deactivateAll() {
//         $filterBtns.removeClass('active');
//     }
// });

var cutElement = $('.filters__event'),
    pasteElement = $('.heading__wrapper'),
    cutRssElement = $('.list__rss'),
    elementWrap = $('<div class="js-cut-to-heading"></div>');
if (cutRssElement.length || cutElement.length) {
    pasteElement.append(elementWrap);
    elementWrap.append(cutElement, cutRssElement);
}

$(".fancybox-video").fancybox({
    helpers: {
        media: {}
    },
    beforeLoad:  function () {
        $('*[tabindex=0]').attr('tabindex', -1).attr('datatabindex', 0);
        $('a, input, button, select, audio, select').attr('tabindex', -1);
    },
    afterClose:  function () {
        $('*[datatabindex=0]').attr('tabindex', 0);
        $('a, button, input, select, audio, select').attr('tabindex', 0);
    }
});

document.addEventListener('DOMContentLoaded', function () {
    var powerMailForm = document.querySelector('.tx-powermail');

    setTimeout(function () {
        if (!powerMailForm) {
            return;
        }

        var formWithStep = powerMailForm.querySelector('.powermail_morestep');
        var sections = [].slice.call(powerMailForm.querySelectorAll('.powermail_fieldset'));

        // Mark the current section with the class 'current'
        function navigateTo(index) {
            sections.forEach(function (section, i) {
                return i === index
                    ? section.classList.add('current')
                    : section.classList.remove('current');
            });
        }

        // Return the current index by looking at which section has the class 'current'
        function curIndex() {
            var ind = null;

            sections.forEach(function (section, index) {
                if (section.classList.contains('current')) {
                    ind = index;
                }
            });

            return ind;
        }

        // Prepare sections by setting the `data-parsley-group` attribute to 'block-0', 'block-1', etc.
        function setAttributes() {
            sections.forEach(function (section, index) {
                var inputs = [].slice.call(section.querySelectorAll('input, select, textarea'));

                inputs.forEach(function (input) {
                    input.setAttribute('data-parsley-group', 'block-' + index);
                });
            });

            navigateTo(0);
        }

        function setAccessibility() {
            setTimeout(function() {
                var pageIndicator = document.querySelector('.index').parentElement;
                pageIndicator.setAttribute('aria-live', 'polite');
                pageIndicator.setAttribute('aria-atomic', 'true');
            }, 100)
        }

        setAccessibility();

        if (formWithStep) {
            setAttributes();

            // Next button goes forward iff current block validates
            var nextBtn = powerMailForm.querySelector('.button-1--next');

            nextBtn.addEventListener('click', function (e) {
                $('.powermail_form').parsley().whenValidate({
                    group: 'block-' + curIndex()
                }).done(function () {
                    navigateTo(curIndex() + 1);
                    setAccessibility();

                    setTimeout(function () {
                        var errorInput = powerMailForm.querySelectorAll('input.parsley-error, select.parsley-error, textarea.parsley-error')[0];

                        if (errorInput) {
                            errorInput.focus();
                        }
                    }, 250);
                });
            });

            $('.powermail_tabmenu li').removeAttr('tabindex');
        }
    }, 0);
});
