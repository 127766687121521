function formatDate(date) {
    function formatZero(num) {
        num = num.toString();
        return num.length > 1 ? num : '0' + num;
    }
    var day = formatZero(date.getDate());
    var month = formatZero(date.getMonth() + 1);
    var year = date.getFullYear();
    return year + month + day;
}
function agendaDatePicker() {
    var yesterday = new Date();
    yesterday.setDate(yesterday.getDate()-1);
    //console.log( formatDate(yesterday) );
    //console.log( typeof formatDate(yesterday) );
    datePickerController.createDatePicker({
        formElements: {
            "input-date-1-jj--hidden": "%d",
            "input-date-1-mm--hidden": "%m",
            "input-date-1-aaaa--hidden": "%Y"
        },
        debug: true,
        staticPos: true,
        fillGrid: true,
        noTodayButton: true,
        // Enable the selection of all dates rendered within the grid
        constrainSelection: false,
        // Remove the associated input from the U.I.
        hideInput: true,
        highlightDays: [0, 0, 0, 0, 0, 0, 0],
        callbackFunctions: {
            //"datereturned": [showInternationalDate],
            "redraw": [highlightEventDates]
        }
    });
    datePickerController.setDisabledDates( "input-date-1-jj--hidden", {"19700101": formatDate(yesterday)});
}
function highlightEventDates(argObj) {
    setTimeout(function(){
        if (agendaEventDates && agendaEventDates.length > 0) {
            // Remove all active atributes
            document.querySelectorAll('[data-highlight]').forEach(function(item) {
                item.removeAttribute('data-highlight');
            });
            // Check dates and add atribute to matched
            agendaEventDates.forEach(function (date) {
                var item = document.querySelector('.cd-' + date);
                if (item && !item.classList.contains('day-disabled')) {
                    item.setAttribute('data-highlight', '');
                }
            })
        }
    },100);
}
function frenchToEnglishDate(frenchDate) {
    var dateElem = frenchDate.split('-');
    return dateElem[1] + '/' + dateElem[2] + '/' + dateElem[0];
}
function showInternationalDate(argObj) {
    var argObjId = argObj.id || argObj.attr('id');
    var argObjDate = argObj.date || new Date(frenchToEnglishDate(argObj.val())),
        inputHidden = document.getElementById(argObjId + '-hidden');
    //inputHidden.value = datePickerController.printFormattedDate(argObjDate, "%Y-%m-%d", false);
    //console.log(argObjDate);
}
function submitAgenda() {
    var el = document.getElementsByTagName('td');
    for (var i = 0; i < el.length; i++) {
        el[i].addEventListener("keypress", function (e) {
            if (e.keyCode == 13 && this.hasAttribute('data-highlight') ) {
                e.preventDefault();
                document.getElementById('agenda-form').submit();
            }
        });
        el[i].addEventListener('click', function () {
            if ( this.hasAttribute('data-highlight') ) {
                window.setTimeout(function () {
                    document.getElementById('agenda-form').submit();
                }, 200);
            }
        });
    }
}
