function geocode() {
    $('.geocode input[type="text"]').each(function () {
        var $this = $(this);
        $this.wrap('<div class="geocode__wrapper"></div>').parent().append('<button type="button" class="button-1 button-1--small button-1--no-text geocode__wrapper-button" title="Déterminer mon adresse actuelle">Déterminer mon adresse actuelle</button>');
        $this.parent().find('button').on('click', function () {
            getAddressFromGeocode($this)
        });
    });
}
function getAddressFromGeocode(field) {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function (position) {
            $.get('http://maps.googleapis.com/maps/api/geocode/json?latlng=' + position.coords.latitude + ',' + position.coords.longitude + '&sensor=true', function (response) {
                if (response)
                    field.val(response.results[0]['formatted_address']);
            });
        });
    }
    else {
        alert('Votre navigateur ne prend pas en charge la géolocalisation.');
    }
    return false;
}
